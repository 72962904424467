import React from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@/components/atoms/Text';
import { computeText } from '@/locales/utils';
import { GuestGroupsByDepthKey } from '@/pages/Brand/Groups/types';
import { useGuestGroupFieldOptions } from '@/hooks/hierarchization/useGuestGroupFieldOptions';
import FieldInfo from '@/components/molecules/Channel/FieldInfo';
import { Select } from '@/components/form/Select';
import { SectionTitle } from './PricingConfigurationSection.style';
import { usePricingRuleFieldOptions } from '@/hooks/hierarchization/usePricingRuleFieldOptions';
import { useHistory } from 'react-router';
import {
    getAccountPaymentTypeOptions,
    getRedirectionLink,
    PRICING_CONFIGURATION_LINKS,
} from '@/utils/factories/hierarchization/hierarchization.utils';

type PricingConfigurationSectionProps = {
    brandId: number;
};

const PricingConfigurationSection: React.FunctionComponent<
    React.PropsWithChildren<PricingConfigurationSectionProps>
> = ({ brandId }) => {
    const intl = useIntl();
    const history = useHistory();

    const {
        groups,
        sections,
        subSections,
        isFetchingGroups,
        isFetchingSections,
        isFetchingSubSections,
        disableSection,
        disableSubSection,
    } = useGuestGroupFieldOptions({ brandId });

    const { pricingRules, isFetchingPricingRules } = usePricingRuleFieldOptions({ brandId });

    const handleRedirectionLinkClick = (link: string, depth?: GuestGroupsByDepthKey) => () => {
        if (brandId) {
            history.push(getRedirectionLink(link, brandId, { depth }));
        }
    };

    return (
        <>
            <SectionTitle>
                <Text text={'customer.form.pricingRuleSection'} weight="bold" />
            </SectionTitle>
            <Select
                labelId="customer.form.accountPaymentTypeLabel"
                name={'accountPaymentType'}
                rules={{
                    required: computeText(intl, 'field.required'),
                }}
                options={getAccountPaymentTypeOptions(intl)}
            />
            <div>
                <Select
                    labelId="customer.student.info.group"
                    name={'groupId'}
                    rules={{
                        required: computeText(intl, 'field.required'),
                    }}
                    options={groups}
                    isLoading={isFetchingGroups}
                />
                <FieldInfo
                    message={computeText(intl, 'customer.form.groupInfo')}
                    handleRedirectionLinkClick={handleRedirectionLinkClick(
                        PRICING_CONFIGURATION_LINKS.GUEST_GROUPS,
                        GuestGroupsByDepthKey.GROUPS,
                    )}
                />
            </div>
            <div>
                <Select
                    labelId="customer.student.info.section"
                    name={'sectionId'}
                    options={sections}
                    isLoading={isFetchingSections}
                    disabled={disableSection}
                />
                <FieldInfo
                    message={computeText(intl, 'customer.form.sectionInfo')}
                    handleRedirectionLinkClick={handleRedirectionLinkClick(
                        PRICING_CONFIGURATION_LINKS.GUEST_GROUPS,
                        GuestGroupsByDepthKey.SECTIONS,
                    )}
                />
            </div>
            <div>
                <Select
                    labelId="customer.student.info.sub.section"
                    name={'subSectionId'}
                    options={subSections}
                    isLoading={isFetchingSubSections}
                    disabled={disableSubSection}
                />
                <FieldInfo
                    message={computeText(intl, 'customer.form.subSectionInfo')}
                    handleRedirectionLinkClick={handleRedirectionLinkClick(
                        PRICING_CONFIGURATION_LINKS.GUEST_GROUPS,
                        GuestGroupsByDepthKey.SUB_SECTIONS,
                    )}
                />
            </div>
            <div>
                <Select
                    labelId="customer.student.pricing.rules.code"
                    name={'pricingRuleId'}
                    options={pricingRules}
                    isLoading={isFetchingPricingRules}
                />
                <FieldInfo
                    message={computeText(intl, 'customer.form.pricingRuleInfo')}
                    handleRedirectionLinkClick={handleRedirectionLinkClick(PRICING_CONFIGURATION_LINKS.PRICING_RULES)}
                />
            </div>
        </>
    );
};

export default PricingConfigurationSection;
