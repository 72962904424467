import { computeText } from '@/locales/utils';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { IntlShape } from 'react-intl';

export type GroupData = {
    group: string;
    section?: string;
    subsection?: string;
    pricingRules: string[];
};

export const getHeaders = ({ intl }: { intl: IntlShape }) => {
    return [
        computeText(intl, 'group.export.group'),
        computeText(intl, 'group.export.section'),
        computeText(intl, 'group.export.subsection'),
        computeText(intl, 'group.export.pricingRule'),
    ];
};

export const getSheetOptions = ({ intl }: { intl: IntlShape }) => {
    const sheetOptions = {
        group: {
            name: 'Groupe',
            headers: getHeaders({ intl }),
            mapper: (data: GroupData) => [data.group, data.section, data.subsection, data.pricingRules.join(', ')],
        },
    };

    return sheetOptions;
};

export const transformGuestGroupsToGroupData = (guestGroups: GuestGroupWithGuestsCount[]): GroupData[] => {
    // First, get all groups (depth 0)
    const groups = guestGroups.filter((g) => g.depth === 0);

    return groups
        .map((group) => {
            // Find all sections for this group (depth 1)
            const sections = guestGroups.filter((g) => g.depth === 1 && g.parentGuestGroupId === group.guestGroupId);

            // For each section, find its subsections (depth 2)
            const sectionsWithSubsections = sections.map((section) => {
                const subsections = guestGroups.filter(
                    (g) => g.depth === 2 && g.parentGuestGroupId === section.guestGroupId,
                );

                return {
                    section: section.name,
                    subsections: subsections.map((sub) => ({
                        group: group.name,
                        section: section.name,
                        subsection: sub.name,
                        pricingRules: sub.pricingRules.map(({ code }) => code),
                    })),
                };
            });

            // If there are no sections or subsections, return just the group
            if (sections.length === 0) {
                return {
                    group: group.name,
                    pricingRules: group.pricingRules.map(({ code }) => code),
                };
            }

            // If there are sections but no subsections, return group with sections
            const result: GroupData[] = [];

            sectionsWithSubsections.forEach((sws) => {
                if (sws.subsections.length === 0) {
                    result.push({
                        group: group.name,
                        section: sws.section,
                        pricingRules:
                            sections.find((s) => s.name === sws.section)?.pricingRules.map(({ code }) => code) || [],
                    });
                } else {
                    result.push(...sws.subsections);
                }
            });

            return result;
        })
        .flat();
};
